<template>
    <Introduction :class="'background-montpellier'">
        <template #title>
            Монтпелье - студенческий город на юге Франции.
        </template>
    </Introduction>
    <Main>
        <template #current>
            Монтпелье
        </template>

        <template #youtube>
            <iframe src="https://www.youtube.com/embed/2A-Zo-fqXWU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>

        <template #pricing>
            <div class="case">
                <div class="title">
                    <h5>РЕСТОРАНЫ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Стоимость обеда/ужина в недорогом ресторане на 1 человека - 12,50 евро</li>
                        <li>Стоимость обеда/ужина на двоих в ресторане среднего уровня (обед/ужин из 3-х блюд) - 60 евро</li>
                        <li>McMeal в Макдональдс - 9 евро</li>
                        <li>Капучино - 2,90 евро</li>
                        <li>Местное пиво - 6 (0,5 литра)</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>МАГАЗИНЫ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Молоко (1 литр) - 0,94 евро</li>
                        <li>Буханка белого хлеба (500 гр) - 1,44 евро</li>
                        <li>Рис (1 кг) - 1,67 евро</li>
                        <li>Яйца (12 шт) - 3,42 евро</li>
                        <li>Местный сыр (1 кг) - 18,50 евро</li>
                        <li>Филе курицы (1 кг) - 11,50 евро</li>
                        <li>Яблоки (1 кг) - 2,55 евро</li>
                        <li>Картофель (1 кг) - 1,42 евро</li>
                        <li>Бутылка вина (средний уровень) - 7 евро</li>
                        <li>Местное пиво (0,5 литра) - 2,95 евро</li>
                        <li>Вода (1,5 литра) - 0,60 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>ТРАНСПОРТ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Билет в 1 конец - 1 евро</li>
                        <li>Абонемент на месяц - 53,50 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>Развлечения </h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Фитнес-клуб, абонемент на месяц - 30,83 евро</li>
                        <li>Билет в кино - 11 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>Проживание</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>1-комнатные апартаменты в центре города - 636 евро</li>
                        <li>3-комнатные апартаменты в центре города - 1125 евро</li>
                    </ul>
                </div>
            </div>
        </template>

        <template #intro>
            <p>
                По версии журнала L’étudiant 2019, это 3-й лучший студенческий город Франции. Монтпелье является также 3-м по численности городом на Средиземном побережье Франции, после Ниццы и Марселя, и 7-м по численности городом в стране. На скоростном поезде можно за 3 часа доехать до Парижа, а также до Барселоны. В 2-х часах езды от города находятся горнолыжные станции, а море всего в 10 километрах. В городе проживает около 285 тысяч человек.
            </p>
            <p>
                В городе расположен один из старейших университетов мира - University of Montpellier , основанный в 1220 году. Студенты составляют около ¼ от всех жителей города.
                С 1990-х годов Монпелье переживает один из самых высоких темпов экономического и демографического роста в стране. В его городских районах наблюдался самый высокий прирост населения во Франции.
            </p>
        </template>

        <template #logement>
            <p>1-комнатные апартаменты в центре города - 636 евро</p>
            <p>3-комнатные апартаменты в центре города - 1125 евро</p>
            <p>La Mosson, La Paillade, Les Cévennes, Figuerolles, Gambetta</p>
            <p>Les Arceaux, Boutonnet, Aiguelongue,  Beaux-Arts, Saint Roch, Port-Marianne</p>
        </template>

    </Main>
    
</template>

<script>
import Introduction from '@/components/city/slots/Introduction.vue'
import Main from '@/components/city/slots/Main.vue'
export default {
    components:{
        Introduction,
        Main
    }
}
</script>