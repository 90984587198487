<template>
    <div class="city">
        <Navbar/>
        <Montpellier/>
    </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import Montpellier from '@/components/city/Montpellier.vue'
export default {
    components: {
        Navbar,
        Montpellier
    }
}
</script>