<template>
    <div class="introduction">
        <div class="container h-100">
            <div class="row d-flex flex-column justify-content-center h-100">
                <div class="col-12 col-lg-7 offset-lg-5 text-details">
                    <div class="introduction-city">
                        <h1><slot name="title"></slot></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
