<template>
    <div class="presentation">
        <div class="container h-100">
            
            <div class="row d-none d-lg-block subnavigation-wrapper" style="margin-bottom:2rem">
                <div class="col-12 ">
                <div class="d-flex flex-row">
                    <div class="link-wrapper">
                    <router-link to="/"><u>Главная</u> ></router-link>
                    </div>
                    <div class="link-wrapper">
                    <router-link to="/cities"><u>Выбрать город</u> ></router-link>
                    </div>
                    <div>
                    <slot name="current"></slot>
                    </div>
                </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-lg-5">
                            <div class="youtube">
                                <slot name="youtube"></slot>
                            </div>
                            <div class="pricing">
                                <h3>ЦЕНЫ</h3>
                                <slot name="pricing"></slot>
                            </div>
                        </div>
                        <div class="col-12 col-lg-7">
                            <div class="intro">
                                <h3>Обзор</h3>
                                <slot name="intro"></slot>
                            </div>

                            <div class="logement">
                                <h3>Проживание</h3>
                                <slot name="logement"></slot>
                            </div>

                            <div class="cta">
                                <h3>
                                    Продолжайте читать об образовании во Франции.
                                </h3>
                                <a href="https://instagram.com/tani.paris" class="btn btn-light">Наш Instagram - 23k</a>
                            </div>

                            <div class="culture" v-if="this.$slots.culture">
                                <h3>Культурные мероприятия и развлечения</h3>
                                <slot name="culture"></slot>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>